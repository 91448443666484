
.BlogSection-section {
	position: relative;
	z-index: 1;
	/* background-image: var(--image-gradient), url(./Assets/temple.svg); */
	padding-bottom: 30px !important;
	// padding-top: 30px !important;
	color: white;
	font-family: "Geo";
  }


