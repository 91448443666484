.social__media__container {
  position: fixed;
  bottom: 30%;
  right: 25px;
  z-index: 3;
  display: flex;
  flex-direction: column;
  animation: movebtn 3s ease-in-out infinite;
  transition: all 0.5s ease-in-out;
}
.social__media__container:hover {
  animation: none;
}

.social_icon_style {
  /* background-color: #551b25; */

  height: 40px;
  width: 40px;
  color: #fff;
  cursor: pointer;
}
.social_icon_style:hover {
  /* background: #fff;
  color: #551b1b; */
  cursor: pointer;
}
@keyframes movebtn {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(15px);
  }
  50% {
    transform: translateY(0px);
  }
  75% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateY(0px);
  }
}
