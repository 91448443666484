// colors
$primary: #1d8cf8;
$secondary: #4f537b;
$info: #11cdef;
$success: #00bf9a;
$warning: #ff8d72;
$danger: #fd5d93;

// timeline variables
$timeline-before-bg: #222a42;
$timeline-body-bg: #1a2332;
$timeline-body-round: 3px;
$timeline-body-shadow: 1px 3px 9px rgba(0, 0, 0, 0.1);

// page container
.container {
  max-width: 650px;
  //   margin: 50px auto;
}

// timeline style
.timeline {
  position: relative;
  padding-left: 4rem;
  margin: 0 0 0 30px;
  color: white;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 4px;
    height: 100%;
    background: $timeline-body-bg;
  }

  .timeline-container {
    position: relative;
    margin-bottom: 2.5rem;

    .timeline-icon {
      position: absolute;
      left: -88px;
      top: 4px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      text-align: center;
      font-size: 2rem;
      background: $secondary;

      i {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    .timeline-body {
      background: $timeline-body-bg;
      border-radius: $timeline-body-round;
      padding: 20px 20px 15px;
      box-shadow: $timeline-body-shadow;

      &:before {
        content: "";
        background: inherit;
        width: 20px;
        height: 20px;
        display: block;
        position: absolute;
        left: -10px;
        transform: rotate(45deg);
        border-radius: 0 0 0 2px;
      }

      .timeline-title {
        margin-bottom: 1.4rem;

        .badge {
          background: $secondary;
          padding: 4px 8px;
          border-radius: 3px;
          font-size: 12px;
          font-weight: bold;
        }
      }

      .timeline-subtitle {
        font-weight: 300;
        font-style: italic;
        opacity: 0.4;
        margin-top: 16px;
        font-size: 11px;
      }
    }

    // Primary Timeline
    &.primary {
      .badge,
      .timeline-icon {
        background: $primary !important;
      }
    }

    // Info Timeline
    &.info {
      .badge,
      .timeline-icon {
        background: $info !important;
      }
    }

    // Success Timeline
    &.success {
      .badge,
      .timeline-icon {
        background: $success !important;
      }
    }

    // Warning Timeline
    &.warning {
      .badge,
      .timeline-icon {
        background: $warning !important;
      }
    }

    // Dnager Timeline
    &.danger {
      .badge,
      .timeline-icon {
        background: $danger !important;
      }
    }
  }
}

.author {
  font-family: inherit;
  padding: 3em;
  text-align: center;
  width: 100%;
  color: white;

  a:link,
  a:visited {
    color: white;
    &:hover {
      text-decoration: none;
    }
  }
  .btn:link,
  .btn:visited {
    margin-top: 1em;
    text-decoration: none;
    display: inline-block;
    font-family: inherit;
    font-weight: 100;
    color: white;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: black;
    padding: 1.5em 2rem;
    border-radius: 1em;
    transition: 0.5s all;
    &:hover,
    &:focus,
    &:active {
      background-color: lighten(black, 10%);
    }
  }
}
