.home-section {
  position: relative;
  z-index: 1;
  /* background-image: var(--image-gradient), url(./Assets/temple.svg); */
  padding-bottom: 150px;
  padding-top: 120px;
  // padding-top: 30px !important;
  color: white;
  font-family: "Geo";
  width: 100%;
}
// .home-content {
//   position: relative;
//   z-index: 4;
//   /* background-image: var(--image-gradient), url(./Assets/temple.svg); */
//   padding-bottom: 30px !important;
//   // padding-top: 30px !important;
//   color: white;
//   font-family: "Geo";
// }

.header-top {
  color: black;
}

.Typewriter__wrapper {
  font-size: 2.2em !important;
  color: #81df6a !important;
  font-weight: 600 !important;
}
.Typewriter__cursor {
  font-size: 2.4em !important;
  color: #81df6a !important;
}

.tech-icons {
  font-size: 3em !important;
  margin: 5px !important;
  padding: 5px !important;
  opacity: 0.93 !important;
  border: 2px solid rgba(30, 179, 16, 0.637) !important;
  vertical-align: middle !important;
  text-align: center !important;
  border-radius: 5px !important;
  display: table !important;
  box-shadow: 4px 5px 4px 3px rgba(89, 4, 168, 0.137) !important;
  overflow: hidden !important;
  transition: all 0.4s ease 0s !important;
  max-height: 100px;
  width: 100%;
}
.tech-icons > p {
  font-size: 0.25em !important;
  margin-bottom: 0px;
}

.hover {
  display: none;
}
.tech-icons:hover {
  transform: scale(1.05) !important;
  overflow: hidden !important;
  border: 2.2px solid rgba(125, 230, 115, 0.883) !important;
  background-color: rgba(125, 230, 115, 0.883);
}
.tech-icons:hover + .hover:hover {
  display: block;
  color: red;
}

.quote-card-view {
  border: none !important;
  // color: white !important;
  background-color: transparent !important;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}
