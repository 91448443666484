.most-popular-img {
  height: 80px;
  width: 100%;
}

.most-popular-font {
  font-weight: 500;
  font-family: "Geo";
}

.most-popular-font-meta {
  font-family: "Geo";
  font-size: 12px;
  color: #777;
}
.card__content {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../../../Assets/featured_posts_1.png");
  display: table-cell;
  background-size: cover;
  flex: 1;
  transition: all 250ms ease-in-out;
}
.card__content:hover {
  cursor: pointer;
  transform: scale(1.05);
}
