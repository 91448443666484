.login-section {
  position: relative;
  z-index: 1;
  /* background-image: var(--image-gradient), url(./Assets/temple.svg); */
  padding-bottom: 30px !important;
  padding-top: 60px !important;
  // padding-top: 30px !important;
  color: white;
  font-family: "Geo";
  width: 100%;
  height: 100%;
}

.btn-sign-in {
  font-family: "Geo";
  min-height: 55px;
  min-width: 120px;
  padding: 0px 10px;
  background-color: #1a2332;
  color: #9fef00;
  transition: (0.5s);
  margin-right: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: inline-block;
  border-radius: 10px;
  border: none;
  font-size: 18px;
}
.btn-sign-in:hover,
.btn-sign-in:focus {
  text-decoration: none;
  filter: brightness(1.6);
}
