.tags {
  display: flex;
  flex-direction: column;
}
.tags__row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.tags__row > a {
  text-decoration: none;
}
.tags > a {
  text-decoration: none;
}
.tag {
  font-family: "Geo";
  
  min-height: 55px;
  min-width: 120px;
  padding: 0px 3px;
  background-color: #1a2332;
  color: #9fef00;
  transition: (0.5s);
  margin-right: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: inline-block;
  border-radius: 10px;
  border: none;
  font-size: 1rem;
}
.tag:hover,
.tag:focus {
  text-decoration: none;
  border: 1px;
  background-color: #dc472d !important;

  // filter: brightness(1.6);
}
