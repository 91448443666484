.skills-section {
  position: relative;
  z-index: 1;
  /* background-image: var(--image-gradient), url(./Assets/temple.svg); */
  padding-bottom: 30px !important;
  padding-top: 60px !important;
  // padding-top: 30px !important;
  // color: white;
  font-family: "Geo";
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.wrapper {
  margin: 0;
  width: 100%;
  height: 60%;
  display: grid;
  grid-gap: 0px;
  font-family: "PT Sans", monospace;
  font-size: 13.5px;
}

.box {
  // background-color: rgba(37, 37, 37, 0.719);
  color: #ffffff;
  border-radius: 0px;
}

.item1 {
  grid-column: 1 / 5;
}

.item2 {
  grid-column: 1 / 3;
  grid-row: 2 / 2;
}

.item3 {
  grid-column: 3 / 5;
}




.skills-bar-container {
  position: relative;
  width: 100%;

  /* min-width: 350px; */
  list-style: none;

  li {
    background: #290505;
    position: relative;
    width: 200px;
    height: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }

  label {
    // color: rgb(255, 255, 255);
    font-size: 1rem;
  }
}

.progressbar {
  position: absolute;
  width: 0%;
  height: 100%;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

/* Software Testing */

#progress-uat {
  -webkit-animation-name: progress-uat;
  animation-name: progress-uat;
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.progressUAT {
  background-color: rgb(21, 255, 0);
}



#progress-fat {
  -webkit-animation-name: progress-fat;
  animation-name: progress-fat;
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.progressFAT {
  background-color: rgb(255, 0, 0);
}

@keyframes progress-fat {
  0% {
    width: 0%;
  }

  100% {
    width: 90%;
  }
}

#progress-pen {
  -webkit-animation-name: progress-pen;
  animation-name: progress-pen;
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.progressPEN {
  background-color: rgb(0, 183, 255);
}

@keyframes progress-pen {
  0% {
    width: 0%;
  }

  100% {
    width: 70%;
  }
}

/* Programing */

#progress-java {
  -webkit-animation-name: progress-java;
  animation-name: progress-java;
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.progressJAVA {
  background-color: rgb(251, 255, 0);
}

@keyframes progress-java {
  0% {
    width: 0%;
  }

  100% {
    width: 75%;
  }
}

#progress-csharp {
  -webkit-animation-name: progress-csharp;
  animation-name: progress-csharp;
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}

.progressCSHARP {
  background-color: rgb(255, 153, 0);
}

@keyframes progress-csharp {
  0% {
    width: 0%;
  }

  100% {
    width: 45%;
  }
}

#progress-dotNet {
  -webkit-animation-name: progress-dotNet;
  animation-name: progress-dotNet;
  -webkit-animation-delay: 2.1s;
  animation-delay: 2.1s;
}

.progressDOTNET {
  background-color: rgb(255, 0, 221);
}

@keyframes progress-dotNet {
  0% {
    width: 0%;
  }

  100% {
    width: 45%;
  }
}

#progress-python {
  -webkit-animation-name: progress-python;
  animation-name: progress-python;
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.progressPYTHON {
  background-color: rgb(0, 59, 18);
}

@keyframes progress-python {
  0% {
    width: 0%;
  }

  100% {
    width: 50%;
  }
}

#progress-sql {
  -webkit-animation-name: progress-sql;
  animation-name: progress-sql;
  -webkit-animation-delay: 2.1s;
  animation-delay: 2.1s;
}

.progressSQL {
  background-color: rgb(251, 255, 0);
}

@keyframes progress-sql {
  0% {
    width: 0%;
  }

  100% {
    width: 75%;
  }
}

#progress-vb {
  -webkit-animation-name: progress-vb;
  animation-name: progress-vb;
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.progressVISUALBASIC {
  background-color: rgb(0, 4, 255);
}

@keyframes progress-vb {
  0% {
    width: 0%;
  }

  100% {
    width: 75%;
  }
}

/* Web Development */

#progress-html {
  -webkit-animation-name: progress-html;
  animation-name: progress-html;
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.progressHTML {
  background-color: rgb(0, 255, 149);
}

@keyframes progress-html {
  0% {
    width: 0%;
  }

  100% {
    width: 95%;
  }
}

#progress-js {
  -webkit-animation-name: progress-js;
  animation-name: progress-js;
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.progressJAVASCRIPT {
  background-color: rgb(255, 81, 0);
}

@keyframes progress-js {
  0% {
    width: 0%;
  }

  100% {
    width: 85%;
  }
}

#progress-php {
  -webkit-animation-name: progress-php;
  animation-name: progress-php;
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.progressPHP {
  background-color: rgb(187, 255, 0);
}

@keyframes progress-php {
  0% {
    width: 0%;
  }

  100% {
    width: 75%;
  }
}

#progress-as {
  -webkit-animation-name: progress-as;
  animation-name: progress-as;
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.progressACTIONSCRIPT {
  background-color: rgb(255, 0, 242);
}

@keyframes progress-as {
  0% {
    width: 0%;
  }

  100% {
    width: 50%;
  }
}



@keyframes progress-angular {
  0% {
    width: 0%;
  }

  100% {
    width: 80%;
  }
}

#progress-angular {
  -webkit-animation-name: progress-angular;
  animation-name: progress-angular;
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.progressANGULAR {
  background-color: rgb(21, 255, 0);
}

@keyframes progress-angular {
  0% {
    width: 0%;
  }

  100% {
    width: 80%;
  }
}

.progressblue {
  background-color: rgb(9, 255, 0);
}




@keyframes progress-react {
  0% {
    width: 0%;
  }

  100% {
    width: 75%;
  }
}

#progress-react {
  -webkit-animation-name: progress-react;
  animation-name: progress-react;
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

.progressREACT {
  background-color: rgb(43, 142, 241);
}

@keyframes progress-react {
  0% {
    width: 0%;
  }

  100% {
    width: 75%;
  }
}



.content-wrapper {
  width: 780px;
  height: 600px;
}


td {
  min-width: fit-content !important;
  max-width: fit-content !important;
  max-height: 10px !important;
  font-size: 14px;
  background-color: #66ff00;
}


.dropdown {
  margin-left: 480px;
}

.color-1 {
  background-color: rgba(208, 255, 0, 0.274);
}

.color-2 {
  background-color: rgba(0, 255, 0, 0.2);
}

.color-3 {
  background-color: rgba(0, 255, 0, 0.3);
}

.color-4 {
  background-color: rgba(0, 255, 0, 0.4);
}

.color-5 {
  background-color: rgba(0, 255, 0, 0.5);
}

.color-6 {
  background-color: rgba(0, 255, 0, 0.6);
}

.color-7 {
  background-color: rgba(0, 255, 0, 0.7);
}

.color-8 {
  background-color: rgba(0, 255, 0, 0.8);
}

.color-9 {
  background-color: rgba(0, 255, 0, 0.9);
}

.color-10 {
  background-color: rgba(0, 255, 0, 1);
}

.color-11 {
  background-color: rgba(255, 72, 0, 0.404);
}

.color-yellow-0 {
  background-color: rgba(255, 255, 0, 0);
}

.color-yellow-1 {
  background-color: rgba(255, 255, 0, 0.1);
}

.color-yellow-2 {
  background-color: rgba(255, 255, 0, 0.2);
}

.color-yellow-3 {
  background-color: rgba(255, 255, 0, 0.3);
}

.color-yellow-4 {
  background-color: rgba(255, 255, 0, 0.4);
}

.color-yellow-5 {
  background-color: rgba(255, 255, 0, 0.5);
}

.color-yellow-6 {
  background-color: rgba(255, 255, 0, 0.6);
}

.color-yellow-7 {
  background-color: rgba(255, 255, 0, 0.7);
}

.color-yellow-8 {
  background-color: rgba(255, 255, 0, 0.8);
}

.color-yellow-9 {
  background-color: rgba(255, 255, 0, 0.9);
}



.mat-dialog-container {
  border: 1px solid #66ff00;
  overflow: hidden !important;
  font-family: 'Geo', sans-serif;
  padding: 8px !important;
  background-color: dimgray;
}


.mat-dialog-title {
  padding: 5px;
  font-family: 'Geo', sans-serif;
  text-align: center;
}


.mat-dialog-actions {
  // align buttons to right as shown in Material Design guide
  justify-content: center;
  font-family: 'Geo', sans-serif;
}