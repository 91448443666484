.footer {
  font-family: "Geo";
  // background-color: #1b1b1be0;
  // background-color: rgb(41, 41, 41);
  // background-image: linear-gradient(to top, rgb(37, 37, 37), rgba(71, 71, 71, 0));
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(140,31,3,1) 68%, rgba(55,143,81,0.7651435574229692) 88%, rgba(0,255,98,0.1881127450980392) 96%, rgba(0,255,119,0) 100%);


  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}
.outer-container {
  background-color: var(--white);
}

.footer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.news-letter {
  display: flex;
  flex-direction: column;
  align-items: center;
  // margin: 25px 5px;

  .letter-title {
    font-size: 2rem;
    color: lightgreen;
    font-weight: 700;
  }

  .letter-description {
    font-size: 1rem;
    color: white;
    // color: #333;
    margin-bottom: 30px;
    text-align: center;
  }

  .form-group {
    display: flex;
    justify-content: center;
    input {
      padding: 10px 15px;
      border-radius: 5px 0 0 5px;
      outline: none;
      border: 1px solid var(--light);
      width: 450px;

      font-size: 1rem;

      &:focus {
        border: 1px solid var(--primary);
      }
    }
  }

  .social-block {
    font-size: 1.3rem;
    // margin: 20px 0;
    i {
      width: 35px;
      height: 35px;
      text-align: center;
      background-color: rgb(202, 83, 192);
      margin: 10px 5px;
      vertical-align: center;
      line-height: 35px;
      border-radius: 5px;
      cursor: pointer;

      &:hover {
        width: 35px;
        height: 35px;
        text-align: center;
        background-color: rgb(206, 67, 25);
        margin: 10px 5px;
        vertical-align: center;
        line-height: 35px;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }
}

.footer-end {
  width: 100%;
  text-align: center;
  border-top: 1px solid var(--dark);
  padding: 10px;
  color: white;
  
  font-size: 15px;
  i {
    vertical-align: middle;
  }
}

@media (max-width: 768) {
  .footer-container {
    margin: 0 30px;
  }
}

@media (max-width: 600px) {
  .footer-container {
    margin: 0 25px;
  }
  .news-letter {
    .form-group {
      input {
        width: 180px !important;
        padding: 10px;
      }
      // button {
      //   padding: 10px;
      // }
    }
  }
}
