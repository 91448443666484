@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;700&display=swap");

.mainBox{
  /* border: 5px solid black; */
  width: 100%;
  height: 100%;
  margin-top: 2em;
}
.mainBox .row{
  width: 100%;
}
.title{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.main-todo-input {
  background: var(--color1);
  width: 100%;
  padding: 0 120px 0 0;
  border-radius: 1px;
  margin-top: 5em;
  box-shadow: 0px 0px 3px 17px rgba(255, 255, 255, 0.3);
}
.myCard{
  border: 5px solid black !important;
  width: 100%;
}
.fl-wrap {
  float: left;
  width: 100%;
  position: relative;
}
.main-todo-input:before {
  content: "";
  position: absolute;
  bottom: -40px;
  width: 50px;
  height: 1px;
  background: rgba(255, 255, 255, 0.41);
  left: 50%;
  margin-left: -25px;
}
.main-todo-input-item {
  float: left;
  width: 100%;
  box-sizing: border-box;
  border-right: 1px solid #eee;
  height: 50px;
  position: relative;
}
.main-todo-input-item input:first-child {
  border-radius: 100%;
}
.main-todo-input-item input {
  float: left;
  border: none;
  width: 100%;
  height: 50px;
  padding-left: 20px;
}
.main-search-button {
  background: #4db7fe;
}
.main-search-button {
  position: absolute;
  right: 0px;
  height: 50px;
  width: 120px;
  color: var(--color1);
  top: 0;
  border: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  cursor: pointer;
}
.main-todo-input-wrap {
  max-width: 500px;
  margin: 20px auto;
  position: relative;
}
:focus {
  outline: 0;
}
@media only screen and (max-width: 768px) {
  .main-todo-input {
    background: rgba(255, 255, 255, 0.2);
    padding: 14px 20px 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 0px 10px rgba(255, 255, 255, 0);
  }
  .main-todo-input-item {
    width: 100%;
    border: 1px solid #eee;
    height: 50px;
    border: none;
    margin-bottom: 10px;
  }
  .main-todo-input-item input {
    border-radius: 6px !important;
    background: var(--color1);
  }
  .main-search-button {
    position: relative;
    float: left;
    width: 100%;
    border-radius: 6px;
  }
}
.todo-listing {
  padding: 0;
  margin-top: 54px !important;
  /* border: 5px solid #4db7fe; */
}