.tags {
  display: flex;
  flex-direction: column;
}
.tags__row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.tags__row > a {
  text-decoration: none;
}
.tags > a {
  text-decoration: none;
}
.tag {
  font-family: "Geo";
  font-size: 11px;
  min-height: 10px;
  min-width: 80px;
  padding: 2px 2px;
  background-color: #1a2332;
  color: #efeb00;
  transition: (0.5s);
  margin-right: 8px;
  margin-top: 2px;
  margin-bottom: 2px;
  display: inline-block;
  border-radius: 5px;
  border: none;
  font-size: 1rem;
}
.tag:hover,
.tag:focus {
  text-decoration: none;
  color: aquamarine;
  filter: brightness(1.6);
}
