.container {
  z-index: 2;
}
.blogpost-detail-title {
  color: white;
  font-family: "Geo";
}

.blogpost-detail-author {
  color: white;
  font-family: "Geo";
}

.blogpost-detail-content {
  width: 100%;
  height: 100%;
}
.blogpost-detail-tags {
  display: flex;
}
.blog__content {
  position: relative;
  display: block;
  padding: 10px;
  color: #eaeaea;
  font-size: 16px;
  line-height: 1.5;
  font-family: Monaco, "Bitstream Vera Sans Mono", "Lucida Console", Terminal,
    monospace;
  overflow-x: auto;
  // word-wrap: break-word !important;
}
.blog__content table {
  word-break: break-word;
  width: 100% !important;
  height: fit-content;
}

.blog__content th {
  color: #ff9100 !important;
  // white-space: normal; /* Only needed when it's set differntly somewhere else */
  // word-wrap: break-word;
}
.blog__content img {
  width: 100%;
  object-fit: contain;
  height: auto;
  max-width: 600px;
  border-radius: 20px;
}
.blog__content a {
  color: #9fef00 !important;
  text-decoration: none;
  width: 100%;
  height: auto;
  overflow-wrap: break-word;
}
.blog__content h1 {
  color: #63c0f5 !important;
}
.blog__content h2 {
  color: #63c0f5 !important;
}
.blog__content h3 {
  color: #63c0f5 !important;
}
.blog__content h4 {
  color: #9fef00 !important;
  margin: 10px 0px;
}
.blog__content li {
  line-height: 25px;
}
.blog__content strong {
  color: #ff9100;
}

.blog__content p {
  white-space: pre-wrap !important;
  white-space: -moz-pre-wrap !important;
  white-space: -pre-wrap !important;
  white-space: -o-pre-wrap !important;
  word-wrap: break-word !important;
  word-break: break-all !important;
  max-width: fit-content;
  overflow-wrap: break-word;
}
.blog__content pre {
  white-space: pre-wrap !important;
  white-space: -moz-pre-wrap !important;
  white-space: -pre-wrap !important;
  white-space: -o-pre-wrap !important;
  word-wrap: break-word !important;
  word-break: break-all !important;

  overflow-wrap: break-word;
}
.blog__content code {
  white-space: pre-wrap !important;
  white-space: -moz-pre-wrap !important;
  white-space: -pre-wrap !important;
  white-space: -o-pre-wrap !important;
  word-wrap: break-word !important;

  padding: 0.5em !important;
  margin: 0.5em 0 !important;
  overflow: auto !important;
  border-radius: 0.3em !important;
  // background: #272822 !important;
  word-break: break-all !important;
}
.blog__content div {
  width: 100%;
  height: fit-content;
}
